import { createSlice } from '@reduxjs/toolkit';
import { Any } from 'react-spring';

interface Roadmap {
    roadmap_id: number;
    roadmap_name: string;
    created_at: Date;
    modified_at: Date;
}

interface RoadMapState {
    data: Roadmap[],
    loading: boolean,
    error: null | object
    members?: string[]
    skills_required?: null | object
    duration?: string
    project_costs?: any
    project_cost?: string
    CPLI?: string
    start_date?: string
    end_date?: string
    durations?: any
    duration_with_working_days?: any
    duration_with_delay?: any
    members_data?: any
    is_sim_pending?: any
    skill_capacity_percent?: any
    roadmaps?: any
    roadmapsError?: any
    roadmapsLoading?: boolean
    saveRoadmapLoading?: boolean
    saveRoadmapError?: any
    saveRoadmapRes?: any
    singleRoadmapLoading:any,
    singleRoadmapRes:any,
    singleRoadmapError:any,
    sim_flag:boolean,
    show_simulation:boolean,
    share_link:boolean,
    taskAllocated:any,
    org_holidays:any,
}

const initialState: RoadMapState = {
    data: [],
    loading: false,
    error: null,
    members:[],
    skills_required:{},
    duration:'',
    duration_with_working_days:'',
    duration_with_delay:'',
    project_cost:'',
    project_costs:'',
    org_holidays:[],
    CPLI:'',
    start_date:'',
    end_date:'',
    durations:[],
    members_data:[],
    is_sim_pending:false,
    skill_capacity_percent:{},
    singleRoadmapLoading:false,
    singleRoadmapRes:[],
    singleRoadmapError:null,
    sim_flag:false,
    show_simulation:true,
    share_link:false,
    taskAllocated:{},
}

export const roadmapSlice = createSlice({
    name: 'roadmap',
    initialState,
    reducers: {
        setRoadmap: (state, action) => {
            state.data = action.payload.Results
            state.skills_required = action.payload.skills_required
            state.duration = action.payload.duration
            state.duration_with_working_days = action.payload.duration_with_working_days
            state.duration_with_delay = action.payload.duration_with_delay
            state.project_cost = action.payload.project_cost
            state.project_costs = action.payload.project_costs
            state.CPLI = action.payload.CPLI
            state.start_date = action.payload.start_date
            state.end_date = action.payload.end_date
            state.durations = action.payload.durations
            state.members_data = action.payload.members_data
            state.is_sim_pending = action.payload.is_sim_pending
            state.skill_capacity_percent = action.payload.skill_capacity_percent
            state.show_simulation = action.payload.show_simulation
            state.share_link = action.payload.share_link
            state.taskAllocated = action.payload.taskAllocated
            state.org_holidays = action.payload.org_holidays
        },
        setRoadmapCPLI: (state, action) => {
            state.skills_required = action.payload.skills_required
            state.duration = action.payload.duration
            state.duration_with_working_days = action.payload.duration_with_working_days
            state.duration_with_delay = action.payload.duration_with_delay
            state.project_cost = action.payload.project_cost
            state.project_costs = action.payload.project_costs
            state.CPLI = action.payload.CPLI
            state.start_date = action.payload.start_date
            state.end_date = action.payload.end_date
            state.durations = action.payload.durations
            state.members_data = action.payload.members_data
            state.is_sim_pending = action.payload.is_sim_pending
            state.skill_capacity_percent = action.payload.skill_capacity_percent
            state.org_holidays = action.payload.org_holidays
        },
        setRoadmapUpdate: (state, action) => {
            state.data = action.payload.Results
            state.duration = action.payload.duration
        },
        setProjectMembers: (state, action) => {
            state.members = action.payload
        },
        setRoadmapLoading: (state, action) => {
            state.loading = action.payload
        },
        setRoadmapError: (state, action) => {
            state.error = action.payload
            state.data = []
        },
        setRoadmaps: (state, action) => {
            state.roadmaps = action.payload?.roadmaps
        },
        setRoadmapsLoading: (state, action) => {
            state.roadmapsLoading = action.payload
        },
        setRoadmapsError: (state, action) => {
            state.roadmapsError = action.payload
            state.data = []
        },
        setSaveRoadmap: (state, action) => {
            state.saveRoadmapRes = action.payload
        },
        setSaveRoadmapLoading: (state, action) => {
            state.saveRoadmapLoading = action.payload
        },
        setSaveRoadmapError: (state, action) => {
            state.saveRoadmapError = action.payload
            state.saveRoadmapRes = []
        },
        setSingleRoadmap: (state, action) => {
            state.singleRoadmapRes = action.payload
        },
        setSingleRoadmapLoading: (state, action) => {
            state.singleRoadmapLoading = action.payload
        },
        setSingleRoadmapError: (state, action) => {
            state.singleRoadmapError = action.payload
            state.saveRoadmapRes = []
        },
        setSimulationToggle: (state, action) => {
            state.sim_flag = action.payload
        }
    }
});

export const { setRoadmap, setRoadmapUpdate, 
    setRoadmapLoading, setRoadmapError,setProjectMembers,setRoadmapCPLI,
    setRoadmapsLoading,setRoadmaps,setRoadmapsError,setSaveRoadmapLoading,
    setSaveRoadmapError,setSaveRoadmap,setSingleRoadmapLoading,setSingleRoadmap,
    setSingleRoadmapError,setSimulationToggle
 } = roadmapSlice.actions;

export default roadmapSlice.reducer;

