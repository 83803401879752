import { createSlice } from '@reduxjs/toolkit';

export const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        data: [],
        orgdata: {},
        skills: [],
        recentProjects: [],
        loading: false,
        skillLoading: false,
        recentProjectsLoading: false,
        skillError: null,
        error: null,
        orgUpdateError:null,
        recentProjectsError:null,
        orgUpdateSuccess:false,
        showSkillEdit:false,
        orgUpdateLoading:false,
        inviteUsersLoading:false,
        inviteUsersError:null,
        inviteUsersSuccess:false,
        jiraSettingsLoading:false,
        jiraSettingsSaveError:null,
        jiraSettingsSuccess:null,
        jiraSettingsError:null,
        asanaSettingsLoading:false,
        asanaSettingsSaveError:null,
        asanaSettingsSuccess:null,
        asanaSettingsError:null,
        projectReferenceLoading:false,
        projectReferenceError:null,
        jiraSettings:{},
        asanaSettings:{},
        onboardSettings:{},
        orgPreferences:[],
        orgPreferencesLoading:false,
        orgPreferencesError:null,
        orgWorkingDays:[],
        orgWorkingDaysLoading:false,
        azureSettingsLoading:false,
        azureSettingsSaveError:null,
        azureSettingsSuccess:null,
        azureSettingsError:null,
        azureSettings:{},
        mondaySettingsLoading:false,
        mondaySettingsSaveError:null,
        mondaySettingsSuccess:null,
        mondaySettingsError:null,
        mondaySettings:{},
        orgSchedule:[],
        orgScheduleLoading:false,
        orgScheduleError:null,
        showOrgScheduleEdit:false,
    },
    reducers: {
        setOrganizationData: (state, action) => {
            state.data = action.payload.projects
        },
        setOrganizationOnboardSettings: (state, action) => {
            state.onboardSettings = action.payload
        },
        setCreateOrganizationData: (state, action) => {
            state.orgdata = action.payload
        },
        setOrganizationLoading: (state, action) => {
            state.loading = action.payload
        },
        setOrganizationError: (state, action) => {
            state.error = action.payload
        },
        setOrganizationSkillData: (state, action) => {
            state.skills = action.payload.skills
        },
        setRecentProjectsData: (state, action) => {
            state.recentProjects = action.payload.projects
        },
        setOrganizationSkillLoading: (state, action) => {
            state.skillLoading = action.payload
        },
        setRecentProjectsLoading: (state, action) => {
            state.recentProjectsLoading = action.payload
        },
        setOrganizationSkillError: (state, action) => {
            state.skillError = action.payload
        },
        setRecentProjectsError: (state, action) => {
            state.recentProjectsError = action.payload
            state.recentProjects = []
        },
        setOrganizationUpdateError: (state, action) => {
            state.orgUpdateError = action.payload
        },
        setOrganizationUpdateSuccess: (state, action) => {
            state.orgUpdateSuccess = action.payload
        },
        setOrganizationUpdateLoading: (state, action) => {
            state.orgUpdateLoading = action.payload
        },
        setShowSkillEdit: (state, action) => {
            state.showSkillEdit = action.payload
        },
        setInviteUsersLoading: (state, action) => {
            state.inviteUsersLoading = action.payload
        },
        setInviteUsersError: (state, action) => {
            state.inviteUsersError = action.payload
        },
        setInviteUsersSuccess: (state, action) => {
            state.inviteUsersSuccess = action.payload
        },

        setJiraSettingsGetSuccess: (state, action) => {
            state.jiraSettingsSuccess = action.payload
        },
        setJiraSettingsGetError: (state, action) => {
            state.jiraSettingsError = action.payload
        },
        setJiraSettingsLoading: (state, action) => {
            state.jiraSettingsLoading = action.payload
        },
        setJiraSettingsSaveSuccess: (state, action) => {
            state.jiraSettingsError = action.payload
        },
        setJiraSettingsSaveError: (state, action) => {
            state.jiraSettingsSaveError = action.payload
        },
        setProjectReferenceSaveError: (state, action) => {
            state.projectReferenceError = action.payload
        },
        setProjectReferenceLoading: (state, action) => {
            state.projectReferenceLoading = action.payload
        },
        projectReferenceRes: (state, action) => {
            state.projectReferenceData = action?.payload?.projects
        },
        setJiraSettings: (state, action) => {
            state.jiraSettings = action.payload
        },
        setAsanaSettings: (state, action) => {
            state.asanaSettings = action.payload
        },
        setAsanaSettingsGetSuccess: (state, action) => {
            state.asanaSettingsSuccess = action.payload
        },
        setAsanaSettingsGetError: (state, action) => {
            state.asanaSettingsError = action.payload
        },
        setAsanaSettingsLoading: (state, action) => {
            state.asanaSettingsLoading = action.payload
        },
        setAsanaSettingsSaveSuccess: (state, action) => {
            state.asanaSettingsError = action.payload
        },
        setAsanaSettingsSaveError: (state, action) => {
            state.asanaSettingsSaveError = action.payload
        },
        setOrgPreferencesLoading: (state, action) => {
            state.orgPreferencesLoading = action.payload
        },
        orgPreferencesRes: (state, action) => {
            state.orgPreferences = action.payload?.preferences
        },
        setOrgPreferencesError: (state, action) => {
            state.orgPreferencesError = action.payload
        },
        setOrgWorkingDays: (state, action) => {
            state.orgWorkingDays = action.payload
        },
        setOrgWorkingDaysLoading: (state, action) => {
            state.orgWorkingDaysLoading = action.payload
        },
        setAzureSettingsGetSuccess: (state, action) => {
            state.azureSettingsSuccess = action.payload
        },
        setAzureSettingsGetError: (state, action) => {
            state.azureSettingsError = action.payload
        },
        setAzureSettingsLoading: (state, action) => {
            state.azureSettingsLoading = action.payload
        },
        setAzureSettingsSaveSuccess: (state, action) => {
            state.azureSettingsError = action.payload
        },
        setAzureSettingsSaveError: (state, action) => {
            state.azureSettingsSaveError = action.payload
        },
        setAzureSettings: (state, action) => {
            state.azureSettings = action.payload
        },
        setMondaySettingsGetSuccess: (state, action) => {
            state.mondaySettingsSuccess = action.payload
        },
        setMondaySettingsGetError: (state, action) => {
            state.mondaySettingsError = action.payload
        },
        setMondaySettingsLoading: (state, action) => {
            state.mondaySettingsLoading = action.payload
        },
        setMondaySettingsSaveSuccess: (state, action) => {
            state.mondaySettingsError = action.payload
        },
        setMondaySettingsSaveError: (state, action) => {
            state.mondaySettingsSaveError = action.payload
        },
        setMondaySettings: (state, action) => {
            state.mondaySettings = action.payload
        },
        setOrgScheduleData: (state, action) => {
            state.orgSchedule = action.payload.holidays
        },
        setOrgScheduleLoading: (state, action) => {
            state.orgScheduleLoading = action.payload
        },
        setOrgScheduleError: (state, action) => {
            state.orgScheduleError = action.payload
        },
        setshoworgScheduleEdit: (state, action) => {
            state.showOrgScheduleEdit = action.payload
        },

    }
});

export const { setOrganizationData,setCreateOrganizationData, setOrganizationLoading, 
    setOrganizationError,setOrganizationSkillLoading,
    setOrganizationSkillError,setOrganizationSkillData,
    setOrganizationUpdateError,setOrganizationUpdateSuccess,
    setOrganizationUpdateLoading,
    setShowSkillEdit, setRecentProjectsLoading,setRecentProjectsData,
    setRecentProjectsError,setInviteUsersLoading,setInviteUsersError,setInviteUsersSuccess,
    setJiraSettingsGetSuccess,setJiraSettingsLoading,setJiraSettingsGetError,
    setJiraSettingsSaveSuccess,setJiraSettingsSaveError,setJiraSettings,
    setProjectReferenceSaveError,setProjectReferenceLoading,projectReferenceRes,
    setOrganizationOnboardSettings,
    setAsanaSettingsGetSuccess,setAsanaSettingsLoading,setAsanaSettingsGetError,
    setAsanaSettingsSaveSuccess,setAsanaSettingsSaveError,setAsanaSettings,
    setOrgPreferencesError,setOrgPreferencesLoading,orgPreferencesRes,setOrgWorkingDays,
    setOrgWorkingDaysLoading,
    setAzureSettingsGetSuccess,setAzureSettingsLoading,setAzureSettingsGetError,
    setAzureSettingsSaveSuccess,setAzureSettingsSaveError,setAzureSettings,
    setMondaySettingsGetSuccess,setMondaySettingsLoading,setMondaySettingsGetError,
    setMondaySettingsSaveSuccess,setMondaySettingsSaveError,setMondaySettings,
    setOrgScheduleData,setOrgScheduleLoading,setOrgScheduleError,setshoworgScheduleEdit
    
 } = organizationSlice.actions;

export default organizationSlice.reducer;

